<template>
  <div class="factory-solution">
    <TopBanner/>
    <div class="advantage special">
      <div class="pdc-title">业务痛点</div>
      <div class="adv-list-item bg1">
        <div class="title">缺乏全盘经销商精细化管理系统</div>
        <div>品牌厂家缺乏全盘经销商管理系统，难以根据经销商具体情况进行营销与市场规划</div>
      </div>
      <div class="adv-list-item bg2">
        <div class="title">业务流程中关键节点效率低</div>
        <div>经销商合同签约、下单、签收、开票、对账等环节，无系统化承载，业务运转效率低</div>
      </div>
      <div class="adv-list-item bg3">
        <div class="title">缺少系统的风险评估体系</div>
        <div>在业务拓展中缺少系统和客观的风险评估体系，只能依赖人工判断</div>
      </div>
      <div class="adv-list-item bg4">
        <div class="title">决策缺少精准的数据支撑</div>
        <div>经营过程中无法及时追踪经销商销售数据，导致销售政策跟不上业务实际情况</div>
      </div>
    </div>
    <div class="solution">
      <div class="pdc-title">解决方案</div>
      <div class="content">仟金顶打破品牌厂家与下游企业之间的业务壁垒，运用人工智能、区块链、云计算、大数据等技术驱动，为品牌厂家提供数字化营销SaaS服务，助力企业降本增效、管控贸易风险。</div>
      <swiper ref="mySwiper" :options="swiperOptions">
        <swiper-slide>
          <img class="left-pic" src="../../../assets/images/solution/factory-1.png">
        </swiper-slide>
        <swiper-slide>
          <img src="../../../assets/images/solution/factory-2.png">
        </swiper-slide>
        <div class="factory-scrollbar" slot="scrollbar"></div>
      </swiper>
    </div>
    <div class="advantage">
      <div class="pdc-title">价值与优势</div>
      <div class="adv-list-item">
        <div class="title">运营效率全面提升</div>
        <div>全链路线上化、数字化的供应链SaaS服务，让品牌厂家业务在线，提高人效，从而降本增效</div>
      </div>
      <div class="adv-list-item">
        <div class="title">经营决策及时准确</div>
        <div>通过对核心关键指标的可视化展现和全盘经销商管理服务，帮助品牌厂家洞察经销商经营变化，为管理决策提供快速、直观、纵览全局的数据支持</div>
      </div>
      <div class="adv-list-item">
        <div class="title">合作风险提前预警</div>
        <div>基于经销商沉淀的真实贸易数据，利用大数据分析和AI能力构建全场景风险评估体系，帮助品牌企业预警合作风险，降低损失</div>
      </div>
    </div>
    <div class="more-pdc">
      <div class="pdc-title">相关产品</div>
      <swiper ref="mySwiper2" :options="swiperOptions2">
        <swiper-slide>
          <div class="item">
            <img class="title-pic" src="../../../assets/images/solution/factory-icon-1.png">
            <div class="info">
              <div class="title">供应链协同</div>
              <div>供应链智能协同系统，打通上下游线上贸易采购全链路</div>
              <div class="linkbtn" @click="toPage('supplychain')"><div>了解详情</div></div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="item">
            <img class="title-pic" src="../../../assets/images/solution/factory-icon-2.png">
            <div class="info">
              <div class="title">赊销系统</div>
              <div>企业自主赊销业务线上化、精细化管理系统</div>
              <div class="linkbtn" @click="toPage('sale')"><div>了解详情</div></div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="item">
            <img class="title-pic" src="../../../assets/images/solution/factory-icon-3.png">
            <div class="info">
              <div class="title">智能合同</div>
              <div>B2B贸易管理线上化，电子签约、电子存证，全链路管理合同</div>
              <div class="linkbtn" @click="toPage('contract')"><div>了解详情</div></div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="item">
            <img class="title-pic" src="../../../assets/images/solution/factory-icon-4.png">
            <div class="info">
              <div class="title">项目管理</div>
              <div>项目全链路线上化协同、管理服务，打通断点、提升管理效率</div>
              <div class="linkbtn" @click="toPage('projectManagement')"><div>了解详情</div></div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="item">
            <img class="title-pic" src="../../../assets/images/solution/factory-icon-5.png">
            <div class="info">
              <div class="title">客户评估</div>
              <div>一站式数据分析评估服务，识别客户、项目资质</div>
              <div class="linkbtn" @click="toPage('developersAssess')"><div>了解详情</div></div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="item">
            <img class="title-pic" src="../../../assets/images/solution/factory-icon-6.png">
            <div class="info">
              <div class="title">风险引擎</div>
              <div>企业贸易伙伴风险、舆情智能监控服务，贸易风险即时预警</div>
              <div class="linkbtn" @click="toPage('riskEngine')"><div>了解详情</div></div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="item">
            <img class="title-pic" src="../../../assets/images/solution/factory-icon-7.png">
            <div class="info">
              <div class="title">经销商分层</div>
              <div>经销商多维智能评估模型，立体分层锁定优质下游企业</div>
              <div class="linkbtn" @click="toPage('layeringDealer')"><div>了解详情</div></div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="item">
            <img class="title-pic" src="../../../assets/images/solution/factory-icon-8.png">
            <div class="info">
              <div class="title">数据驾驶舱</div>
              <div>数据可视化看板，赋能企业经营决策</div>
              <div class="linkbtn" @click="toPage('dataCockpit')"><div>了解详情</div></div>
            </div>
          </div>
        </swiper-slide>
        <div class="factory2-scrollbar" slot="scrollbar"></div>
      </swiper>
    </div>
    <SignButton></SignButton>
  </div>
</template>

<script>
import TopBanner from '@/components/TopBanner'
import SignButton from '@/components/signButton'
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
export default {
  components: {
    TopBanner, SignButton, swiper, swiperSlide
  },
  data() {
    return {
      swiperOptions: {
        scrollbar: {
          el: '.factory-scrollbar',
        },
        freeMode : true,
      },
      swiperOptions2: {
        scrollbar: {
          el: '.factory2-scrollbar',
        },
        slidesPerView: 1.96,
        spaceBetween: 15,
        slidesOffsetBefore: 20,
        slidesOffsetAfter: 20,
        freeMode : true,
      },
    
    }
  },
  mounted() {
  },
  methods: {
    toPage(name) {
      this.$router.push({name})
    }
  }
}
</script>

<style lang="scss">
@import '../../../assets/style/product.scss';
.factory-solution{
  position: relative;
  background-color: #fff;
  color: #252631;
  font-size: 24px;
  .top-banner-product{
    .bg{
      background-color: #fff;
    }
    .front{
      color: #252631;
      .btn2{
        color: #FFF;
      }
    }
  }
  .special{
    .adv-list-item{
      background-color: #F8F9FB;
      background-size: auto 100%;
      background-position: center right;
      background-repeat: no-repeat;
      border: none;
      &.bg1{
        background-image: url('../../../assets/images/solution/factory-bg-1.png');
      }
      &.bg2{
        background-image: url('../../../assets/images/solution/factory-bg-2.png');
      }
      &.bg3{
        background-image: url('../../../assets/images/solution/factory-bg-3.png');
      }
      &.bg4{
        background-image: url('../../../assets/images/solution/factory-bg-4.png');
      }
    }
  }
  .solution{
    background: url('../../../assets/images/solution/solution-bg.png');
    background-size: 100% auto;
    background-repeat: no-repeat;
    color: #fff;
    .pdc-title{
      color: #fff;
    }
    .content{
      padding: 0 38px 38px;
      font-size: 24px;
      line-height: 36px;
    }
    img{
      display: block;
      line-height: 0px;
      width: 710px;
    }
    .left-pic{
      margin-left: 40px;
      width: 710px;
    }
    .factory-scrollbar{
      height: 8px;
      width: 100px;
      margin: 0 auto;
      margin-top: 26px;
      margin-bottom: 20px;
      background-color: #FFF;
      border-radius: 8px;
      .swiper-scrollbar-drag{
        background-color: #CEB287;
      }
    }
  }
  .more-pdc{
    background-color: #F5F5F7;
    .item{
      display: flex;
      flex-direction: column;
      box-shadow: 5px 8px 16px 0px rgba(180, 180, 190, 0.26);
      border: 1Px solid #FFF;
      width: 360px;
      font-size: 24px;
      height: 504px;
      .title-pic{
        display: block;
        width: 138px;
        margin: 0 auto;
        padding-top: 48px;
      }
      .info{
        flex: 1 0 auto;
        line-height: 32px;
        padding: 35px 42px;
        color: #595961;
        text-align: center;
        display: flex;
        flex-direction: column;
        font-size: 24px;
        .title{
          font-size: 28px;
          line-height: 42px;
          color: #252631;
          font-weight: bold;
          margin-bottom: 18px;
        }
        .linkbtn{
          flex: 1 0 auto;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          div{
            display: block;
            color: #FFF;
            background-color: #3D538C;
            font-size: 24px;
            padding: 10px 22px;
          }
        }
      }
    }
    .factory2-scrollbar{
      height: 8px;
      width: 100px;
      margin: 0 auto;
      margin-top: 40px;
      margin-bottom: 86px;
      background-color: #EAEBEE;
      border-radius: 8px;
      .swiper-scrollbar-drag{
        background-color: #CEB287;
      }
    }
  }
}
</style>