<template>
  <div class='sign-bombtn'>
    <div class="block">
      <router-link to='/friend/cooperate' class="sign-btn">在线申请·立享数字化服务</router-link>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="scss">
.sign-bombtn{
  height: 167px;
  position: absolute;
  bottom: -870px;
  width: 750px;
  border-top: 1PX solid #EAEBEE;
  .block{
    border-top: 1PX solid #EAEBEE;
    z-index: 2;
    position: fixed;
    bottom: 0;
    width: 750px;
    height: 167px;
    background-color: #fff;
    color: #fff;
    .sign-btn{
      display: block;
      color: #FFF;
      background-color: #CEB287;
      margin: 9px 38px;
      padding: 18px;
      text-align: center;
      font-size: 32px;
      line-height: 45px;
    }
  }
}
</style>