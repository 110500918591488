<template>
  <div class="top-banner-product">
    <div class="bg"><img :src="pageInfo.img"></div>
    <div class="front">
      <div class="text">
        <div class="title">
          {{ pageInfo.title }}
        </div>
        <div v-for="item in pageInfo.subList" :key='item' class="sub-title">{{ item }}</div>
      </div>
      <div class="btn-area">
        <div class="btn btn1" @click='toServePage'>免费咨询</div>
        <router-link to='/friend/cooperate' class="btn btn2">申请试用</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
      pageInfo: {}
    }
  },
  mounted() {
    const pageInfo = {
      supplychain: { title: '供应链协同', subList: ['供应链智能化全链路追踪、管理、上下游', '贸易协同系统'], img: require('../../assets/images/product/top-supplychain.png') },
      sale: { title: '赊销系统', subList: ['细分垂直行业赊销管理能力，降低品牌厂', '家赊销风险，推动业务健康发展'], img: require('../../assets/images/product/top-sale.png') },
      bidding: { title: '招投标管理', subList: ['企业一站式招投标查询、订阅推送服务，', '帮助企业即时了解招标情况，把握销售', '商机'], img: require('../../assets/images/product/top-bidding.png') },
      projectManagement: { title: '项目管理', subList: ['项目全链路线上化协同、管理服务，打通', '断点、提升管理效率'], img: require('../../assets/images/product/top-projectManage.png') },
      contract: { title: '智能合同', subList: ['合同无纸化，更安全、更简便'], img: require('../../assets/images/product/top-contract.png') },
      salesTools: { title: '销售工具', subList: ['自定义营销工具，一键生成链接在线获客'], img: require('../../assets/images/product/top-salesTools.png') },
      crm: { title: 'CRM', subList: ['轻量化管理客户关系，客户跟踪进度随时', '更新，帮助企业做好客户开发及过程管控'], img: require('../../assets/images/product/top-crm.png') },
      erp: { title: 'ERP', subList: ['轻量化管理产品、库存、订单信息等，帮', '助企业在业务开展过程中，提升运营效率'], img: require('../../assets/images/product/top-erp.png') },
      layeringDealer: { title: '经销商分层', subList: ['经销商诚信等级评估，为经销商评优和汰', '换提供依据'], img: require('../../assets/images/product/top-layeringDealer.png') },
      riskEngine: { title: '风险引擎', subList: ['全网多源信息采集及跟踪，对合作企业经', '营及履约风险进行动态管理，支持多渠道', '推送预警触达'], img: require('../../assets/images/product/top-riskEngine.png') },
      eye: { title: '仟金眼', subList: ['企业商业报告查询服务，深度剖析企业基', '本资质，锁定优质合作'], img: require('../../assets/images/product/top-eye.png') },
      developersAssess: { title: '客户评估', subList: ['一站式数据分析服务：透查建筑工程商、', '项目资质'], img: require('../../assets/images/product/top-developersAssess.png') },
      projectAppraisal: { title: '项目评估', subList: ['项目风险深度评估服务，专家评估，精准', '识别项目风险'], img: require('../../assets/images/product/top-projectAppraisal.png') },
      dataCockpit: { title: '数据驾驶舱', subList: ['通过详尽的指标分析，对核心关键指', '标的可视化展现，为管理决策提供快', '速、直观、纵览全局的数据支持'], img: require('../../assets/images/product/top-dataCockpit.png') },
      factory: { title: '品牌厂家', subList: ['为品牌厂家提供一站式数字化营销', 'SaaS服务解决方案，助力品牌厂家', '降本增效、管控贸易风险'], img: require('../../assets/images/solution/top-factory.png') },
      resources: { title: '资源赋能', subList: ['仟金顶引入外部资源，如物流仓储、银行等，为企业提', '供基于贸易采购的全闭环的供应链服务，帮助企业提升', '业务运转效率，快速抢占市场'], img: require('../../assets/images/product/top-resources.png') },
      open: { title: '开放平台', subList: ['提供标准开发接口，实现业务交互线上化，', '降低人工对接的人力成本'], img: require('../../assets/images/product/top-open.png') },
      company: { title: '小微企业', subList: ['为小微企业提供业务开拓、线上贸易', '协同、风险监控、风险评估等核心解', '决方案，助力企业业务在线化管理，', '实现降本增效'], img: require('../../assets/images/solution/top-company.png') },
      partner: { title: '赋能小微企业 推动产业升级', subList: ['科技驱动型供应链综合服务平台'], img: require('../../assets/images/partner/top-partner.png') },
    }
    this.pageInfo = pageInfo[this.$route.name]
  },
  methods: {
    toServePage() {
      window.location = 'https://im.7x24cc.com/phone_webChat.html?accountId=N000000015742&chatId=6bb1e11d-94e8-4af1-a07f-a2e8f1e6a68d'
    }
  }
}
</script>

<style lang="scss">
.top-banner-product{
  background-color: #fff;
  position: relative;
  .bg{
    height: 400px;
    background-color: #252631;
    img{
      width: 100%;
    }
  }
  .front{
    position: absolute;
    top: 0;
    width: 100%;
    height: 400px;
    color: #fff;
    padding: 54px 38px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .title{
      font-size: 36px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .sub-title{
      font-size: 24px;
      font-weight: 200;
    }
    .btn-area{
      display: flex;
      .btn{
        padding: 16px 24px;
        font-size: 28px;
      }
      .btn1{
        border: 1PX solid #979797;
      }
      .btn2{
        display: block;
        color: #FFF;
        background-color: #3A5AA1;
        margin-left: 30px;
      }
    }
  }
}
</style>