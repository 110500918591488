<template>
  <div class="company-solution">
    <TopBanner/>
    <div class="advantage special">
      <div class="pdc-title">业务痛点</div>
      <div class="adv-list-item bg1">
        <div class="title">商机获取困难，客户管理混乱</div>
        <div>缺乏线上化营销获客工具，商机获取难；客户管理跟进无系统承载，人员效率低</div>
      </div>
      <div class="adv-list-item bg2">
        <div class="title">业务信息化程度低，运转流程长</div>
        <div>内部无信息化系统，业务过程管控效率低，导致业务运转慢</div>
      </div>
      <div class="adv-list-item bg3">
        <div class="title">缺少系统的风险评估体系</div>
        <div>在业务拓展中缺少系统和客观的风险评估体系，只能依赖人工判断</div>
      </div>
    </div>
    <div class="solution">
      <div class="pdc-title">解决方案</div>
      <div class="content">仟金顶深耕泛建材行业，基于各垂直领域行业特性，深入供应链上下游贸易，以采购、销售等真实贸易场景为基础，通过供应链和数据SaaS承载，为小微企业提供数字化管理服务，帮助小微企业发掘商机、提升业务效率、把控业务风险，助力小微企业数字化转型。</div>
      <swiper ref="mySwiper" :options="swiperOptions">
        <swiper-slide>
          <img class="left-pic" src="../../../assets/images/solution/company-1.png">
        </swiper-slide>
        <swiper-slide>
          <img src="../../../assets/images/solution/company-2.png">
        </swiper-slide>
        <div class="company-scrollbar" slot="scrollbar"></div>
      </swiper>
    </div>
    <div class="advantage">
      <div class="pdc-title">价值与优势</div>
      <div class="adv-list-item">
        <div class="title">业务拓展更高效</div>
        <div>为企业提供自定义的营销工具和全流程的客户管理系统，获客更快捷、客户关系管理更清晰</div>
      </div>
      <div class="adv-list-item">
        <div class="title">业务决策更精准、科学</div>
        <div>利用数字化的风险监控工具和可视化的数据看板，为企业提供切实可靠、纵览全局的数据支持，助力企业科学化决策，为业务顺利高效开展保驾护航</div>
      </div>
      <div class="adv-list-item">
        <div class="title">业务运转更快捷</div>
        <div>基于全流程的线上项目管理和内部协同系统，助力企业打通流程断点、提高协同效率，最终实现业务的高效运转</div>
      </div>
    </div>
    <div class="more-pdc">
      <div class="pdc-title">相关产品</div>
      <swiper ref="mySwiper2" :options="swiperOptions2">
        <swiper-slide>
          <div class="item">
            <img class="title-pic" src="../../../assets/images/solution/company-icon-1.png">
            <div class="info">
              <div class="title">销售工具</div>
              <div>自定义营销工具，一键生成链接在线获客</div>
              <router-link to='/product/salesTools' class="linkbtn"><div>了解详情</div></router-link>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="item">
            <img class="title-pic" src="../../../assets/images/solution/company-icon-2.png">
            <div class="info">
              <div class="title">CRM</div>
              <div>多渠道客户统筹管理，在线协同跟进客户，赋能客户转化</div>
              <router-link to='/product/crm' class="linkbtn"><div>了解详情</div></router-link>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="item">
            <img class="title-pic" src="../../../assets/images/solution/company-icon-3.png">
            <div class="info">
              <div class="title">ERP</div>
              <div>商品、库存、订单系统化管理服务，业务在线管理</div>
              <router-link to='/product/erp' class="linkbtn"><div>了解详情</div></router-link>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="item">
            <img class="title-pic" src="../../../assets/images/solution/company-icon-4.png">
            <div class="info">
              <div class="title">招投标管理</div>
              <div>全国招投标信息实时查询服务，即时订阅推送，把握销售商机</div>
              <router-link to='/product/bidding' class="linkbtn"><div>了解详情</div></router-link>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="item">
            <img class="title-pic" src="../../../assets/images/solution/company-icon-5.png">
            <div class="info">
              <div class="title">智能合同</div>
              <div>B2B贸易管理线上化，电子签约、电子存证，全链路管理合同</div>
              <router-link to='/product/contract' class="linkbtn"><div>了解详情</div></router-link>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="item">
            <img class="title-pic" src="../../../assets/images/solution/company-icon-6.png">
            <div class="info">
              <div class="title">项目管理</div>
              <div>项目全链路线上化协同、管理服务，打通断点、提升管理效率</div>
              <router-link to='/product/projectManagement' class="linkbtn"><div>了解详情</div></router-link>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="item">
            <img class="title-pic" src="../../../assets/images/solution/company-icon-7.png">
            <div class="info">
              <div class="title">客户评估</div>
              <div>一站式房开产业链数据分析评估服务，识别开发商、项目资质</div>
              <router-link to='/product/developersAssess' class="linkbtn"><div>了解详情</div></router-link>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="item">
            <img class="title-pic" src="../../../assets/images/solution/company-icon-8.png">
            <div class="info">
              <div class="title">项目评估</div>
              <div>项目风险深度评估服务，专家评估，精准识别项目风险</div>
              <router-link to='/product/projectAppraisal' class="linkbtn"><div>了解详情</div></router-link>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="item">
            <img class="title-pic" src="../../../assets/images/solution/company-icon-9.png">
            <div class="info">
              <div class="title">仟金眼</div>
              <div>企业商业报告查询服务，深度剖析企业基本资质，锁定优质合作</div>
              <router-link to='/product/eye' class="linkbtn"><div>了解详情</div></router-link>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="item">
            <img class="title-pic" src="../../../assets/images/solution/company-icon-10.png">
            <div class="info">
              <div class="title">数据看板</div>
              <div>数据可视化看板，赋能企业经营决策</div>
              <router-link to='/product/dataCockpit' class="linkbtn"><div>了解详情</div></router-link>
            </div>
          </div>
        </swiper-slide>
        <div class="company2-scrollbar" slot="scrollbar"></div>
      </swiper>
    </div>
    <SignButton></SignButton>
  </div>
</template>

<script>
import TopBanner from '@/components/TopBanner'
import SignButton from '@/components/signButton'
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
export default {
  components: {
    TopBanner, SignButton, swiper, swiperSlide
  },
  data() {
    return {
      swiperOptions: {
        scrollbar: {
          el: '.company-scrollbar',
        },
        freeMode : true,
      },
      swiperOptions2: {
        scrollbar: {
          el: '.company2-scrollbar',
        },
        slidesPerView: 1.96,
        spaceBetween: 15,
        slidesOffsetBefore: 20,
        slidesOffsetAfter: 20,
        freeMode : true,
      },
    
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="scss">
@import '../../../assets/style/product.scss';
.company-solution{
  position: relative;
  background-color: #fff;
  color: #252631;
  font-size: 24px;
  .top-banner-product{
    .bg{
      background-color: #fff;
    }
    .front{
      color: #252631;
      .btn2{
        color: #FFF;
      }
    }
  }
  .special{
    .adv-list-item{
      background-color: #F8F9FB;
      background-size: auto 100%;
      background-position: center right;
      background-repeat: no-repeat;
      border: none;
      &.bg1{
        background-image: url('../../../assets/images/solution/company-bg-1.png');
      }
      &.bg2{
        background-image: url('../../../assets/images/solution/company-bg-2.png');
      }
      &.bg3{
        background-image: url('../../../assets/images/solution/company-bg-3.png');
      }
    }
  }
  .solution{
    background: url('../../../assets/images/solution/solution-bg.png');
    background-size: 100% auto;
    background-repeat: no-repeat;
    color: #fff;
    .pdc-title{
      color: #fff;
    }
    .content{
      padding: 0 38px 38px;
      font-size: 24px;
      line-height: 36px;
    }
    img{
      width: 710px;
    }
    .left-pic{
      margin-left: 40px;
    }
    .company-scrollbar{
      height: 8px;
      width: 100px;
      margin: 0 auto;
      margin-bottom: 20px;
      background-color: #FFF;
      border-radius: 8px;
      .swiper-scrollbar-drag{
        background-color: #CEB287;
      }
    }
  }
  .more-pdc{
    background-color: #F5F5F7;
    .item{
      display: flex;
      flex-direction: column;
      box-shadow: 5px 8px 16px 0px rgba(180, 180, 190, 0.26);
      border: 1Px solid #FFF;
      width: 360px;
      font-size: 24px;
      height: 504px;
      .title-pic{
        display: block;
        width: 138px;
        margin: 0 auto;
        padding-top: 48px;
      }
      .info{
        flex: 1 0 auto;
        line-height: 32px;
        padding: 35px 42px;
        color: #595961;
        text-align: center;
        display: flex;
        flex-direction: column;
        font-size: 24px;
        .title{
          font-size: 28px;
          line-height: 42px;
          color: #252631;
          font-weight: bold;
          margin-bottom: 18px;
        }
        .linkbtn{
          flex: 1 0 auto;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          div{
            display: block;
            color: #FFF;
            background-color: #3D538C;
            font-size: 24px;
            padding: 10px 22px;
          }
        }
      }
    }
    .company2-scrollbar{
      height: 8px;
      width: 100px;
      margin: 0 auto;
      margin-top: 40px;
      margin-bottom: 86px;
      background-color: #EAEBEE;
      border-radius: 8px;
      .swiper-scrollbar-drag{
        background-color: #CEB287;
      }
    }
  }
}
</style>